import { dumpLogs } from "@modules/home/hooks/dumpLogs";
import { FigmaJsonStates } from "@modules/home/view/activity/Components/Blocks/AllInOne/FigmaStates";
import { delay } from "@utils/helpers";
import debounce from "lodash/debounce";

class Sound {
    private sounds: Record<
        string,
        {
            path: string;
            url: string;
            blob?: string;
            loaded: boolean;
            audio?: HTMLAudioElement | null;
            props?: any;
            duration: number | null;
        }
    > = {};
    showPopup = () => {};
    removePopup = () => {};
    onError = (e: any) => {};
    pauseSound = false;
    questionAudios: any[] = [];
    constructor() {
        this.handleAudioInterruption();
    }

    private handleAudioInterruption() {
        if (typeof window == "undefined") return;

        // For iOS
        if (navigator.platform.match(/(iPhone|iPod|iPad)/i)) {
            document.addEventListener("visibilitychange", () => {
                if (document.hidden) {
                    dumpLogs({
                        action: "sound_pause_visibility_change",
                        pauseSound: this.pauseSound,
                    });
                    this.pause();
                } else {
                    // Optionally resume playback when the app comes back to foreground
                    // this.resume();
                }
            });

            window.addEventListener("pagehide", () => {
                dumpLogs({
                    action: "sound_pause_page_hide",
                    pauseSound: this.pauseSound,
                });
                this.pause();
            });

            // Periodic check for unexpected pauses
            setInterval(() => {
                const audioElement = document.getElementById(
                    "interactionSoundComponent",
                ) as HTMLAudioElement;

                if (audioElement && !audioElement.paused && document.hidden) {
                    dumpLogs({
                        action: "sound_pause_periodic",
                        pauseSound: this.pauseSound,
                    });
                    this.pause();
                }
            }, 1000); // Check every second
        }

        // For Android
        if (/Android/.test(navigator.userAgent)) {
            window.addEventListener("blur", () => {
                dumpLogs({
                    action: "sound_pause_blur",
                    pauseSound: this.pauseSound,
                });
                this.pause();
            });

            // Optional: resume playback when focus is regained
            window.addEventListener("focus", () => {
                // this.resume();
            });
        }

        // General approach for modern browsers
        if ("mediaSession" in navigator) {
            navigator.mediaSession.setActionHandler("pause", () => {
                dumpLogs({
                    action: "sound_pause_action",
                    pauseSound: this.pauseSound,
                });
                this.pause();
            });
        }

        // Handle audio context state changes
        const audioContext = new (window.AudioContext ||
            window?.webkitAudioContext)();
        audioContext.onstatechange = () => {
            if (audioContext?.state === "interrupted") {
                dumpLogs({
                    action: "sound_pause_interrupt",
                    pauseSound: this.pauseSound,
                });
                this.pause();
            } else if (audioContext.state === "running") {
                // Optionally resume playback
                // this.resume();
            }
        };
    }
    resetSrc = (audioElement: HTMLAudioElement) => {
        if (!audioElement) return;
        const events = [
            "onerror",
            "onabort",
            "oncancel",
            "onclose",
            "onstalled",
            "onloadstart",
            "ondurationchange",
            "onloadeddata",
            "onprogress",
            "oncanplay",
            "oncanplaythrough",
            "onloadedmetadata",
            "onended",
            "onpause",
            "onplay",
        ];
        for (const event of events) {
            audioElement[event] = null;
        }
        audioElement.src = "";
    };
    preloadAudio(name: string, url: string, props?: any) {
        dumpLogs({
            action: "preload_audio_start",
            sound_name: name,
            sound_url: url,
            sound: this.sounds[name],
            pauseSound: this.pauseSound,
        });
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const audio = new Audio();
                const objectUrl = URL.createObjectURL(blob);
                audio.src = objectUrl;
                audio.preload = "auto";
                // once this file loads, it will call loadedAudio()
                // the file will be kept by the browser as cache
                const dumpLogCallback = (action: string) => {
                    dumpLogs({
                        action,
                        sound_name: name,
                        sound_url: url,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                };
                audio.onloadstart = () =>
                    dumpLogCallback("preload_audio_load_start");
                audio.ondurationchange = () =>
                    dumpLogCallback("preload_audio_duration_change");
                audio.onloadeddata = () =>
                    dumpLogCallback("preload_audio_loaded_data");
                audio.onprogress = () =>
                    dumpLogCallback("preload_audio_progress");
                audio.oncanplay = () =>
                    dumpLogCallback("preload_audio_can_play");
                audio.oncanplaythrough = () => {
                    if (this.sounds[name]) this.sounds[name].loaded = true;
                    props?.loaded && props?.loaded();
                    dumpLogs({
                        action: "preload_audio_can_play_through",
                        sound_name: name,
                        sound_url: url,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                };
                if (!this.sounds[name])
                    this.sounds[name] = {
                        path: url,
                        url,
                        loaded: false,
                        props,
                    };
                this.sounds[name].audio = audio;
                this.sounds[name].blob = objectUrl;
                audio.onloadedmetadata = () => {
                    props?.setDuration && props?.setDuration(audio.duration);
                    // get duration from audio
                    if (this.sounds[name])
                        this.sounds[name].duration = audio.duration;
                    dumpLogs({
                        action: "preload_audio_loaded_metadata",
                        sound_name: name,
                        sound_url: url,
                        sound: this.sounds[name],
                        duration: audio.duration,
                        pauseSound: this.pauseSound,
                    });
                };
                audio.src = url;
                audio.load();
            })
            .catch((e) => {
                const audio = new Audio(url);
                audio.preload = "auto";
                audio.onloadeddata = () => {
                    dumpLogs({
                        action: "preload_audio_loaded_data",
                        sound_name: name,
                        sound_url: url,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                    this.sounds[name].loaded = true;
                };
                audio.load();
                audio.onerror = (e) => {
                    dumpLogs({
                        action: "preload_audio_fail",
                        sound_name: name,
                        sound: this.sounds[name],
                        tError: e?.target?.error,
                        tErrorMsg: e?.target?.error?.message,
                        eventPointer: e,
                        pauseSound: this.pauseSound,
                    });
                };
                dumpLogs({
                    sound_url: url,
                    action: "preload_audio_fail_fetch",
                    sound_name: name,
                    sound: this.sounds[name],
                    tError: e?.target?.error,
                    tErrorMsg: e?.target?.error?.message,
                    eventPointer: e,
                    pauseSound: this.pauseSound,
                });
            });
    }
    add(name: string, path: string, props?: any) {
        if (this.sounds[name] && this.sounds[name].path == path) {
            props?.setDuration &&
                props?.setDuration(this.sounds[name].duration);
            return;
        }
        this.sounds[name] = {
            path,
            url: path,
            loaded: false,
            props,
        };
        this.preloadAudio(name, path, props);
    }
    pauseIfPopupIsEnabled() {
        // pause if popup-layout is visible
        const div = document.getElementById("popup-layout");
        if (div) {
            dumpLogs({
                action: "audio_pause_if_popup_is_enabled",
                pauseSound: this.pauseSound,
            });
            this.pause();
        }
    }
    getSoundName(audioElement: HTMLAudioElement): string {
        const url = decodeURIComponent(audioElement.src);
        const isBlob = url.includes("blob");
        return (
            Object.keys(this.sounds).find(
                (i) => this.sounds[i][isBlob ? "blob" : "url"] == url,
            ) ?? ""
        );
    }

    play(
        name: string,
        callbacks: {
            interaction?: boolean;
            complete?: () => void;
            onPlay?: () => void;
            error?: (arg0: String, ...rest: any) => void;
        } = {},
    ) {
        dumpLogs({
            action: "play_audio_start",
            sound_name: name,
            sound: this.sounds[name],
            pauseSound: this.pauseSound,
        });
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        this.resetSrc(audioElement);
        if (!this.sounds[name]) {
            dumpLogs({
                action: "play_audio_not_found",
                sound_name: name,
                sound: this.sounds,
                pauseSound: this.pauseSound,
            });
            callbacks.error && callbacks.error("NOT_FOUND");
            return;
        }
        try {
            const failCallBack = debounce((e, msg) => {
                this.resetSrc(audioElement);
                const error = audioElement.error;
                if (String(e).includes("interact with the document first")) {
                    dumpLogs({
                        action: "play_audio_fail_no_tap",
                        sound_name: name,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                    callbacks.error && callbacks.error("NO_TAP");
                } else {
                    dumpLogs({
                        action: "play_audio_fail",
                        sound_name: name,
                        sound: this.sounds[name],
                        msg,
                        error,
                        tError: e?.target?.error,
                        tErrorMsg: e?.target?.error?.message,
                        eventPointer: e,
                        pauseSound: this.pauseSound,
                    });
                    callbacks.error && callbacks.error(msg, e);
                }
                return;
            }, 100);
            const attachEvent = (audioElement: HTMLAudioElement) => {
                audioElement.onended = () => {
                    dumpLogs({
                        action: "play_audio_ended",
                        sound_name: name,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                    this.resetSrc(audioElement);
                    callbacks.complete && callbacks.complete();
                };
                audioElement.onpause = (e) => {
                    if (callbacks.interaction && !audioElement.ended) {
                        this.showPopup();
                    }
                    dumpLogs({
                        action: "play_audio_pause",
                        sound_name: name,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                    this.pauseCallback();
                };
                this.pauseIfPopupIsEnabled();
                audioElement.onplay = () => {
                    if (callbacks.interaction && !audioElement.ended) {
                        this.removePopup();
                    }
                    if (callbacks?.onPlay) {
                        callbacks.onPlay();
                    }
                    dumpLogs({
                        action: "play_audio_on_play",
                        sound_name: name,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                };
                audioElement.onerror = (e) => failCallBack(e, "ERROR");
                audioElement.onabort = (e) => failCallBack(e, "ABORT");
                audioElement.oncancel = (e) => failCallBack(e, "CANCEL");
                audioElement.onclose = (e) => failCallBack(e, "CLOSE");
            };
            const playCatch = (e: any) => {
                if (
                    String(e).includes(
                        "The play() request was interrupted by a call to pause()",
                    )
                ) {
                    this.showPopup?.();
                    delay(50).then(() => this.pause());
                    return;
                }

                if (String(e).includes("interact with the document first")) {
                    dumpLogs({
                        action: "play_audio_no_tap",
                        sound_name: name,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                    this.onError("NO_TAP");
                    callbacks.error && callbacks.error("NO_TAP");
                } else {
                    dumpLogs({
                        action: "play_audio_play_error",
                        sound_name: name,
                        sound: this.sounds[name],
                        eventPointer: e,
                        error: e?.message,
                        tError: e?.target?.error,
                        tErrorMsg: e?.target?.error?.message,
                        pauseSound: this.pauseSound,
                    });
                    callbacks.error && callbacks.error("PLAY_ERROR");
                }
                this.resetSrc(audioElement);
                this.pauseSound = false;
            };
            if (this.sounds[name].loaded) {
                audioElement.src = this.sounds[name].path;
                audioElement.load();
                attachEvent(audioElement);
                if (!this.pauseSound) audioElement.play().catch(playCatch);
                else this.showPopup();
            } else {
                let skipped = false;
                let interval: any = setTimeout(() => {
                    skipped = true;
                    failCallBack(null, "TIMEOUT");
                    dumpLogs({
                        action: "play_audio_timeout",
                        sound_name: name,
                        sound: this.sounds[name],
                        pauseSound: this.pauseSound,
                    });
                    interval = null;
                    return;
                }, 2000);
                fetch(this.sounds[name].path)
                    .then((response) => response.blob())
                    .then((blob) => {
                        if (skipped) return;
                        const objectUrl = URL.createObjectURL(blob);
                        audioElement.src = objectUrl;
                        this.sounds[name].blob = objectUrl;
                        audioElement.oncanplaythrough = () => {
                            if (interval) clearTimeout(interval);
                            if (skipped) return;
                            dumpLogs({
                                action: "play_audio_can_play_through",
                                sound_name: name,
                                sound: this.sounds[name],
                                pauseSound: this.pauseSound,
                            });
                            if (!this.pauseSound)
                                audioElement.play().catch(playCatch);
                        };
                        attachEvent(audioElement);
                        audioElement.load();
                    })
                    .catch((e) => {
                        if (interval) clearTimeout(interval);
                        const audio = new Audio(this.sounds[name].path);
                        audio.preload = "auto";
                        audio.onloadeddata = () => {
                            if (this.sounds[name])
                                this.sounds[name].loaded = true;
                        };
                        audio.onerror = (e) => {
                            failCallBack(e, "FETCH_AUDIO");
                        };
                        audio.load();
                        failCallBack(e, "FETCH");
                    });
                // callbacks.error('NOT_LOADED');
                // wait for the audio to load
            }
        } catch (e) {
            callbacks.error && callbacks.error("FAILED", e);
        }
    }

    playDirect(
        url: string,
        callbacks: {
            interaction?: boolean;
            complete?: () => void;
            onPlay?: () => void;
            error?: (arg0: String, ...rest: any) => void;
        },
    ) {
        if (!url) {
            callbacks.error && callbacks.error("ERROR");
            return;
        }
        dumpLogs({
            action: "direct_play_audio_start",
            sound_url: url,
            pauseSound: this.pauseSound,
        });
        const failCallBack = debounce((e, msg) => {
            this.resetSrc(audioElement);
            const error = audioElement.error;
            if (String(e).includes("interact with the document first")) {
                dumpLogs({
                    action: "direct_play_audio_no_tap",
                    sound_url: url,
                    pauseSound: this.pauseSound,
                });
                callbacks.error && callbacks.error("NO_TAP");
            } else {
                dumpLogs({
                    action: "direct_play_audio_fail",
                    sound_url: url,
                    msg,
                    error,
                    tError: e?.target?.error,
                    tErrorMsg: e?.target?.error?.message,
                    eventPointer: e,
                    pauseSound: this.pauseSound,
                });
                callbacks.error && callbacks.error(msg, e);
            }
            return;
        }, 100);
        if (!url) {
            failCallBack(null, "ERROR");
            return;
        }
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        audioElement.src = url;
        audioElement.load();
        const dumpLogCallback = (action: string) => {
            dumpLogs({
                action,
                sound_url: url,
                pauseSound: this.pauseSound,
            });
        };
        audioElement.onloadstart = () =>
            dumpLogCallback("direct_play_audio_load_start");
        audioElement.ondurationchange = () =>
            dumpLogCallback("direct_play_audio_duration_change");
        audioElement.onloadedmetadata = () =>
            dumpLogCallback("direct_play_audio_loaded_metadata");
        audioElement.onloadeddata = () =>
            dumpLogCallback("direct_play_audio_loaded_data");
        audioElement.onprogress = () =>
            dumpLogCallback("direct_play_audio_progress");
        audioElement.oncanplay = () =>
            dumpLogCallback("direct_play_audio_can_play");
        audioElement.oncanplaythrough = () => {
            dumpLogs({
                action: "direct_play_audio_can_play_through",
                sound_url: url,
                pauseSound: this.pauseSound,
            });
            audioElement.onplay = () => {
                if (callbacks.interaction && !audioElement.ended) {
                    this.removePopup();
                }
                if (callbacks?.onPlay) {
                    callbacks.onPlay();
                }
                dumpLogs({
                    action: "direct_play_audio_on_play",
                    sound_url: url,
                    pauseSound: this.pauseSound,
                });
            };
            audioElement.play().catch((e) => {
                this.resetSrc(audioElement);
                if (String(e).includes("interact with the document first")) {
                    dumpLogs({
                        action: "direct_play_audio_no_tap",
                        sound_url: url,
                        pauseSound: this.pauseSound,
                    });
                    this.onError("NO_TAP");
                    callbacks.error && callbacks.error("NO_TAP");
                } else {
                    dumpLogs({
                        action: "direct_play_audio_error",
                        sound_url: url,
                        eventPointer: e,
                        error: e?.message,
                        tError: e?.target?.error,
                        tErrorMsg: e?.target?.error?.message,
                        pauseSound: this.pauseSound,
                    });
                }
            });
            audioElement.onended = () => {
                this.resetSrc(audioElement);
                callbacks.complete && callbacks.complete();
                dumpLogs({
                    action: "direct_play_audio_ended",
                    sound_url: url,
                    pauseSound: this.pauseSound,
                });
            };
        };
        audioElement.onerror = (e) => failCallBack(e, "ERROR");
    }
    find(name: string) {
        return this.sounds[name];
    }
    pauseCallback() {
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        const timeout = setTimeout(() => {
            const div = document.getElementById("popup-layout");
            if (
                audioElement.paused &&
                !audioElement.ended &&
                !div &&
                !document.hidden
            ) {
                const sound_name = this.getSoundName(audioElement);
                const playCatch = (e: any) => {
                    if (
                        String(e).includes(
                            "The element has no supported sources.",
                        )
                    ) {
                        return;
                    }
                    if (
                        String(e).includes("interact with the document first")
                    ) {
                        dumpLogs({
                            action: "play_audio_no_tap",
                            sound_name,
                            sound: this.sounds[sound_name],
                            pauseSound: this.pauseSound,
                        });
                        this.onError("NO_TAP");
                    } else {
                        dumpLogs({
                            action: "play_audio_play_error_pause_callback",
                            sound_name,
                            sound: this.sounds[sound_name],
                            eventPointer: e,
                            error: e?.message,
                            tError: e?.target?.error,
                            tErrorMsg: e?.target?.error?.message,
                            pauseSound: this.pauseSound,
                        });
                    }
                };
                dumpLogs({
                    action: "play_audio_auto_unpause",
                    sound_url: audioElement.src,
                    sound_name: this.getSoundName(audioElement),
                    pauseSound: this.pauseSound,
                });
                audioElement.play().catch(playCatch);
                this.pauseSound = false;
            }
            clearTimeout(timeout);
        }, 1000);
    }
    pause(name: string = "") {
        this.pauseSound = true;
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        dumpLogs({
            action: "play_audio_pause",
            sound_url: audioElement.src,
            sound_name: this.getSoundName(audioElement),
            pauseSound: this.pauseSound,
        });
        audioElement.pause();
        this.showPopup();
        this.pauseCallback();
    }
    resume(name: string = "") {
        this.pauseSound = false;
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        const sound_name = this.getSoundName(audioElement);
        const playCatch = (e: any) => {
            if (String(e).includes("The element has no supported sources.")) {
                return;
            }
            if (String(e).includes("interact with the document first")) {
                dumpLogs({
                    action: "play_audio_no_tap",
                    sound_name,
                    sound: this.sounds[sound_name],
                    pauseSound: this.pauseSound,
                });
                this.onError("NO_TAP");
            } else {
                dumpLogs({
                    action: "play_audio_play_error_resume",
                    sound_name,
                    sound: this.sounds[sound_name],
                    eventPointer: e,
                    error: e?.message,
                    tError: e?.target?.error,
                    tErrorMsg: e?.target?.error?.message,
                    pauseSound: this.pauseSound,
                });
            }
        };
        dumpLogs({
            action: "play_audio_resume",
            sound_url: audioElement.src,
            sound_name: this.getSoundName(audioElement),
            pauseSound: this.pauseSound,
        });
        audioElement.play().catch(playCatch);
    }
    stop(name: string) {
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        const sound_name = this.getSoundName(audioElement);
        if (sound_name != name) return;
        dumpLogs({
            action: "play_audio_stop",
            sound_url: audioElement.src,
            sound_name,
            pauseSound: this.pauseSound,
        });
        audioElement.pause();
        audioElement.currentTime = 0;
        this.resetSrc(audioElement);
    }

    stopAll() {
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        if (!audioElement.src) return;
        dumpLogs({
            action: "play_audio_stop_all",
            sound_url: audioElement.src,
            pauseSound: this.pauseSound,
        });
        audioElement.pause();
        audioElement.currentTime = 0;
        this.resetSrc(audioElement);
    }
    forceEnd() {
        const audioElement = document.getElementById(
            "interactionSoundComponent",
        ) as HTMLAudioElement;
        if (!audioElement.src) return;
        dumpLogs({
            action: "play_audio_force_end",
            sound_url: audioElement.src,
            pauseSound: this.pauseSound,
            sound_name: this.getSoundName(audioElement),
        });
        if (isNaN(audioElement.duration)) return;
        audioElement.currentTime = audioElement.duration;
        this.pauseSound = false;
    }
    removeAll() {
        dumpLogs({
            action: "audio_remove_all",
            sounds: this.sounds,
            pauseSound: this.pauseSound,
        });
        const countDownSound = this.sounds["Count-down-sound"];
        this.sounds = { "Count-down-sound": countDownSound };
        this.questionAudios = [];
    }
    removeOne(name: string) {
        dumpLogs({
            action: "audio_remove_one",
            sounds: this.sounds,
            name,
            pauseSound: this.pauseSound,
        });
        if (this.sounds[name]) delete this.sounds[name];
    }

    addQuestionAudiosList() {
        this.questionAudios =
            FigmaJsonStates.getNonUpdatedJson("question_audios");
    }
}

export const SoundState = new Sound();
