var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d9583a89453b33b50b9a2f3557d291fc5783ef2c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
    init,
    replayIntegration,
    BrowserProfilingIntegration,
    captureConsoleIntegration,
    browserTracingIntegration,
    browserApiErrorsIntegration,
    extraErrorDataIntegration,
    dedupeIntegration,
    globalHandlersIntegration,
    httpClientIntegration,
    sessionTimingIntegration,
    replayCanvasIntegration,
} from "@sentry/nextjs";
import Bowser from "bowser";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
    dsn:
        SENTRY_DSN ||
        "https://4e29b6150c95a6e3f1f030c8b295108e@o4508057977815040.ingest.us.sentry.io/4508057980305408",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [
        replayCanvasIntegration(),
        replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [
                window.location.origin,
                "https://(?:[a-zA-Z0-9-]+.)*mathai.ai/",
                "https://(?:[a-zA-Z0-9-]+.)*homeworkapp.ai/",
            ],
        }),
        new BrowserProfilingIntegration(),
        browserApiErrorsIntegration({
            setTimeout: true,
            setInterval: true,
            requestAnimationFrame: true,
            XMLHttpRequest: true,
            eventTarget: true,
        }),
        captureConsoleIntegration({ levels: ["error"] }),
        extraErrorDataIntegration(),
        dedupeIntegration(),
        globalHandlersIntegration({
            onerror: true,
            onunhandledrejection: true,
        }),
        httpClientIntegration(),
        sessionTimingIntegration(),
        browserTracingIntegration(),
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
});
